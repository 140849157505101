'use client';

import { Box, IconButton, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { MoreVertOutlined } from 'ui/icons';

import { useResolution } from '../../hooks';
import { Avatar } from '../Avatar';
import { Paper } from '../Paper';
import { TypographyOverflow } from '../TypographyOverflow';

interface Props {
  name: string;
  description: React.ReactNode;
  content: React.ReactNode;
  menuActions?: { icon?: React.ReactNode; onClick: () => void; label: string }[];
}

const PersonCard = ({ name, description, content, menuActions }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { customShadows } = useTheme();
  const open = Boolean(anchorEl);
  const { isTablet } = useResolution();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Paper
      sx={{
        boxShadow: customShadows.main,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        mx: 0,
        p: 4,
      }}
    >
      <Stack alignItems="center" textAlign="center" mb={1} position="relative">
        {menuActions && (
          <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton onClick={handleClick} sx={{ height: 30, width: 30 }}>
              <MoreVertOutlined fontSize={isTablet ? 'large' : 'small'} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {menuActions.map(({ icon, onClick, label }, index) => (
                <MenuItem key={index} onClick={onClick}>
                  {icon}
                  <Typography ml={1} display="inline">
                    {label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}

        <Avatar shape="rounded" size="large" name={name} />

        <TypographyOverflow fontWeight="bold" mb={1} mt={1.8} variant={isTablet ? 'h6' : 'subtitle1'}>
          {name}
        </TypographyOverflow>
        {description}
      </Stack>

      <Box mt={1}>{content}</Box>
    </Paper>
  );
};
export default PersonCard;

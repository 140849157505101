import { Box } from '../Box/Box';
import { Link } from '../Link';
import { Logo } from '../Logo';
import { Typography } from '../Typography/Typography';

export type ErrorFallbackProps = {
  title: string;
  description: string;
  supportEmail: string;
  showLogo?: boolean;
};

export const ErrorFallback = ({ title, description, supportEmail, showLogo = true }: ErrorFallbackProps) => {
  return (
    <Box display="flex" alignItems="center" height="100%" justifyContent="center" maxWidth="1000px" mx="auto">
      <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column">
        {showLogo && (
          <Box mb={2}>
            <Logo />
          </Box>
        )}

        <Box mb={2}>
          <Typography variant="h4">{title}</Typography>
        </Box>

        <Typography variant="subtitle1" textAlign="justify">
          {description}
        </Typography>

        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
      </Box>
    </Box>
  );
};

import { createContext, ReactNode, Reducer, useMemo, useReducer } from 'react';

import { CHANGE_STATION_INFO_FORM_STATE } from './constants';

const defaultContext: StationContextType = {
  state: {
    stations: [],
    newStation: {
      measuringPoint: '',
      stationName: '',
      energySource: '',
      otherEnergySource: '',
      exploitationDate: '',
      power: 0,
      erpRegion: [],
      region: null,
      municipality: null,
      settlement: null,
      address: '',
    },
  },

  dispatch: () => null,
};

const stateReducer: Reducer<StationInfoState, Action> = (prevState: StationInfoState, action: Action) => {
  switch (action.type) {
    case CHANGE_STATION_INFO_FORM_STATE:
      return {
        ...prevState,
        ...action.payload,
      };

    default:
      return prevState;
  }
};

export const StationContext = createContext<StationContextType>(defaultContext);

interface Props {
  children: ReactNode;
}

export const StationContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<Reducer<StationInfoState, Action>>(stateReducer, defaultContext.state);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <StationContext.Provider value={contextValue}>{children}</StationContext.Provider>;
};

import { Box, Stack, Switch, Typography, useTheme } from '@mui/material';

import { useResolution } from '../../hooks';

interface Props {
  onChangeHandler: () => void;
  options: [string, string];
  checked: boolean;
  'data-testid'?: string;
  disabled?: boolean;
}

export const Toggle = ({ onChangeHandler, options, checked, 'data-testid': dataTestId, disabled = false }: Props) => {
  const theme = useTheme();
  const { isMobile } = useResolution();

  const fontSize = isMobile ? theme.spacing(2.5) : theme.spacing(2);
  return (
    <Stack
      direction={isMobile ? 'row' : 'column'}
      spacing={theme.spacing(1)}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h6" fontSize={fontSize}>
        {options[0]}
      </Typography>
      <Box sx={{ transform: 'scale(1.5)' }}>
        <Switch
          checked={checked}
          disabled={disabled}
          onChange={onChangeHandler}
          data-testid={dataTestId}
          sx={{
            '&.MuiSwitch-root .Mui-checked': {
              color: theme.palette.primary.main,
            },
            '&.MuiSwitch-root .MuiSwitch-switchBase': {
              color: theme.palette.primary.main,
            },
            '&.MuiSwitch-root .MuiSwitch-track': {
              backgroundColor: `${theme.palette.grey[400]} !important`,
            },
          }}
        />
      </Box>
      <Typography variant="h6" fontSize={fontSize}>
        {options[1]}
      </Typography>
    </Stack>
  );
};

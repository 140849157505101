import { Actions, addAction } from 'rum';

interface NpsData {
  email?: string;
  name?: string;
  properties?: Record<string, unknown>;
}
export function useNps({ email, name, properties }: NpsData) {
  return () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delighted.survey({
      initialDelay: 0,
      forceDisplay: true,
      email,
      name,
      properties,
      onShow: () => addAction(Actions.SHOW_NPS),
      onRespond: () => addAction(Actions.RESPONSE_NPS),
    });
  };
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTP_CONTENT_TYPES = exports.VALID_ENVIRONMENTS = exports.FILE_MIME_TYPES = exports.FILE_FORMATS = void 0;
var FILE_FORMATS;
(function (FILE_FORMATS) {
    FILE_FORMATS["PDF"] = ".pdf";
    FILE_FORMATS["JPG"] = ".jpg";
    FILE_FORMATS["JPEG"] = ".jpeg";
    FILE_FORMATS["PNG"] = ".png";
    FILE_FORMATS["HEIC"] = ".heic";
    FILE_FORMATS["HEIF"] = ".heif";
})(FILE_FORMATS || (exports.FILE_FORMATS = FILE_FORMATS = {}));
var FILE_MIME_TYPES;
(function (FILE_MIME_TYPES) {
    FILE_MIME_TYPES["PDF"] = "application/pdf";
    FILE_MIME_TYPES["JPG"] = "image/jpeg";
    FILE_MIME_TYPES["PNG"] = "image/png";
    FILE_MIME_TYPES["HEIC"] = "image/heic";
    FILE_MIME_TYPES["HEIF"] = "image/heif";
})(FILE_MIME_TYPES || (exports.FILE_MIME_TYPES = FILE_MIME_TYPES = {}));
var VALID_ENVIRONMENTS;
(function (VALID_ENVIRONMENTS) {
    VALID_ENVIRONMENTS["development"] = "development";
    VALID_ENVIRONMENTS["local"] = "local";
    VALID_ENVIRONMENTS["production"] = "production";
    VALID_ENVIRONMENTS["localhost"] = "localhost";
})(VALID_ENVIRONMENTS || (exports.VALID_ENVIRONMENTS = VALID_ENVIRONMENTS = {}));
var HTTP_CONTENT_TYPES;
(function (HTTP_CONTENT_TYPES) {
    HTTP_CONTENT_TYPES["BLOB"] = "application/octet-stream";
    HTTP_CONTENT_TYPES["JSON"] = "application/json";
    HTTP_CONTENT_TYPES["PDF"] = "application/pdf";
})(HTTP_CONTENT_TYPES || (exports.HTTP_CONTENT_TYPES = HTTP_CONTENT_TYPES = {}));

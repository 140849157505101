'use client';

import { FormControl, InputLabel, Select, SelectProps as MuiSelectProps } from '@mui/material';
import React from 'react';

export type SelectProps<T> = MuiSelectProps<T>;

const SelectField = <T,>({ id, label, fullWidth, children, required, ...rest }: SelectProps<T>, ref: React.Ref<T>) => (
  <FormControl fullWidth={fullWidth} required={required}>
    {label && <InputLabel id={id}>{label}</InputLabel>}
    <Select fullWidth={fullWidth} labelId={id} label={label} inputRef={ref} {...rest}>
      {children}
    </Select>
  </FormControl>
);

export default React.forwardRef(SelectField) as <T>(props: SelectProps<T>, ref: React.Ref<T>) => JSX.Element;

'use client';

import { Box, ButtonBase, ClickAwayListener, Collapse, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import logger from 'logger';
import { useState } from 'react';

import { countriesData, countriesIsoCodes, CountryIsoCode, isValidCountry } from '../../config';
import { ExpandMore } from '../../icons';
import { Flag } from '../Flag';
import { FlagSelect } from '../FlagSelect';

export type SelectedCountry = {
  countryPhoneCode: string;
  countryIsoCode: CountryIsoCode;
};

export interface CountryDropdownProps {
  defaultCountry?: CountryIsoCode;
  name: string;
  disabled?: boolean;
  onSelect: (selectedCountry: SelectedCountry) => void;
}

const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{
  isOpen: boolean;
}>(({ theme, isOpen }) => ({
  '&:hover': {
    borderColor: isOpen ? theme.palette.primary.main : theme.palette.grey[800],
  },
  border: '1px solid',
  borderBottomLeftRadius: '12px',
  borderColor: isOpen ? theme.palette.primary.main : theme.palette.grey[400],
  borderTopLeftRadius: '12px',
  color: theme.palette.grey[800],
  height: '56px',
  padding: theme.spacing(2),
}));

export const CountryDropdown = ({ defaultCountry, name, onSelect, disabled = false }: CountryDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const theme = useTheme();

  const selectNewCountry = (newCountry: string) => {
    if (!isValidCountry(newCountry)) {
      logger.debug(`Invalid country ${newCountry}`);
      return;
    }
    setSelectedCountry(newCountry);
    onSelect({ countryIsoCode: newCountry, countryPhoneCode: countriesData[newCountry].countryCode });
    setIsOpen(false);
  };

  const handleClick = (event: React.MouseEvent) => {
    selectNewCountry(event.currentTarget.id);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    selectNewCountry((event.target as HTMLDivElement).id);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  return (
    <Box position="relative" data-testid={`country-flag-${name}`}>
      <StyledButtonBase
        isOpen={isOpen}
        id="menu-button"
        data-testid="flag-dropdown-button"
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedCountry && (
          <Box width={theme.spacing(8)} display="flex">
            <Flag isoCode={selectedCountry} />
            <Typography ml={1}>{countriesData[selectedCountry].countryCode}</Typography>
          </Box>
        )}
        <ExpandMore sx={{ transform: isOpen ? 'rotate(-90deg)' : 'rotate(0)' }} />
      </StyledButtonBase>

      {isOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Collapse in={isOpen}>
            <Box
              mt={0.5}
              border={1}
              position="absolute"
              borderColor="grey.400"
              borderRadius={theme.spacing(1)}
              left={0}
              zIndex={10}
              maxHeight={theme.spacing(30)}
              width={theme.spacing(40)}
              sx={{ backgroundColor: theme.palette.common.white, transformOrigin: 'top left' }}
              data-testid="country-dropdown-list"
              overflow="auto"
            >
              <Box role="none">
                {countriesIsoCodes.map((isoCode) => (
                  <Box
                    p={theme.spacing(1)}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.grey[50],
                      },
                      backgroundColor: selectedCountry === isoCode ? theme.palette.primary.light : 'inherit',
                    }}
                    data-testid={`country-dropdown-list-item-${isoCode}`}
                    id={isoCode}
                    key={isoCode}
                    onClick={handleClick}
                    onKeyPress={handleKeyPress}
                    role="button"
                    tabIndex={0}
                  >
                    <FlagSelect isoCode={isoCode} data-testid={`flag-select-${name}`} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Collapse>
        </ClickAwayListener>
      )}
    </Box>
  );
};

import { Box } from './Box/Box';
import { Typography } from './Typography/Typography';

export const Stepper = ({ steps, step }: { steps: Record<string, string>; step: string }) => {
  const stepWizardArray = Object.keys(steps);
  return (
    <>
      <Typography variant="h5">{steps[step]}</Typography>
      <Box display="grid" gridTemplateColumns={`repeat(${stepWizardArray.length}, minmax(0, 1fr))`} gap={2} mt={1}>
        {stepWizardArray.map((currStep, i) => (
          <Box
            key={currStep + i}
            height={2}
            bgcolor={stepWizardArray.indexOf(step) >= i ? 'primary.main' : 'common.black'}
          />
        ))}
      </Box>
    </>
  );
};

import { useTranslation } from 'next-export-i18n';
import { ReactNode } from 'react';
import { Box, Footer } from 'ui';

export const Layout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" justifyContent="between" minHeight="100vh">
      <Box mb={8}>{children}</Box>
      <Footer email={t('shared.company.producerSalesEmail')} />
    </Box>
  );
};

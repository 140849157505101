'use client';

import { useTheme } from '@mui/material/styles';
import { CheckCircleOutline } from 'ui/icons';

import { Button } from './Button';
import { Container } from './Container';
import { Paper } from './Paper';
import { Typography } from './Typography/Typography';

type Props = {
  heading: string;
  title?: string;
  subtitle?: string;
  buttonConfig?: { text: string; href: string };
};

export const SuccessMessage = ({ heading, title, subtitle, buttonConfig }: Props) => {
  const theme = useTheme();

  return (
    <Container>
      <Paper sx={{ textAlign: 'center', width: 'fit-content' }}>
        <Typography variant="h4" color={theme.palette.primary.main} mb={2}>
          {heading}
        </Typography>
        <Typography variant="h6">{title}</Typography>
        <CheckCircleOutline sx={{ color: theme.palette.primary.main, fontSize: theme.spacing(8) }} />
        <Typography variant="h6" mb={3}>
          {subtitle}
        </Typography>

        {buttonConfig && <Button href={buttonConfig.href}>{buttonConfig.text}</Button>}
      </Paper>
    </Container>
  );
};

import { useMediaQuery, useTheme } from '@mui/material';

export const useResolution = () => {
  const { breakpoints } = useTheme();

  return {
    isDesktop: useMediaQuery(breakpoints.up('lg')),
    isLargeDesktop: useMediaQuery(breakpoints.up('xl')),
    isLargeTablet: useMediaQuery('(min-width:960px)'),
    isMobile: useMediaQuery(breakpoints.up('sm')),
    isSmallMobile: useMediaQuery(breakpoints.up('xs')),
    isTablet: useMediaQuery(breakpoints.up('md')),
  };
};

import { Container as MuiContainer } from '@mui/material';

type Props = React.ComponentProps<typeof MuiContainer> & {
  children: React.ReactNode;
};

export const Container = ({ children, ...rest }: Props) => {
  return (
    <MuiContainer sx={{ padding: '1rem' }} {...rest}>
      {children}
    </MuiContainer>
  );
};

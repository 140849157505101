'use client';

import { IconButton, Popover, PopoverOrigin } from '@mui/material';
import React, { ChangeEvent, MouseEvent, useState } from 'react';

import { useResolution } from '../../hooks';
import { Search } from '../../icons';
import TextField, { TextFieldProps } from '../TextField';

interface Props extends TextFieldProps {
  onSearch: (q: string) => void;
  popoverPosition?: 'left' | 'right';
}

const SearchField = (props: TextFieldProps) => (
  <TextField
    fullWidth
    {...props}
    InputProps={{
      autoComplete: 'off',
      startAdornment: <Search />,
    }}
    sx={{
      minWidth: 200,
    }}
  />
);

const SearchControl = ({ onSearch, popoverPosition = 'left', ...rest }: Props) => {
  const anchorOrigin: PopoverOrigin = {
    horizontal: popoverPosition,
    vertical: 'center',
  };
  const transformOrigin: PopoverOrigin = {
    horizontal: popoverPosition === 'left' ? 'right' : 'left',
    vertical: 'center',
  };

  const { isTablet } = useResolution();
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    onSearch(event.target.value);
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isTablet) {
    return <SearchField value={searchQuery} onChange={handleSearchChange} {...rest} />;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'search-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleOpen} data-testid="search-icon-button">
        <Search fontSize="large" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={{
          '.MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      >
        <SearchField value={searchQuery} onChange={handleSearchChange} {...rest} />
      </Popover>
    </>
  );
};

export default SearchControl;

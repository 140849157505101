import { useTranslation } from 'next-export-i18n';
import { ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { addError, Errors } from 'rum';
import { ErrorFallback, ErrorFallbackProps, ThemeProvider } from 'ui';

import {
  CompanyContextProvider,
  ProjectContextProvider,
  RequestContextProvider,
  StationContextProvider,
} from '~/context';

type AppProviderProps = {
  children: ReactNode;
};

const myErrorHandler = (error: Error, info: ErrorInfo) => {
  addError(Errors.UNHANDLED_ERROR, error, {
    info,
  });
};

const FallbackComponent = ({ title, description, supportEmail }: ErrorFallbackProps) => (
  <ErrorFallback title={title} description={description} supportEmail={supportEmail} />
);

export const AppProvider = ({ children }: AppProviderProps) => {
  const { t } = useTranslation();
  const errorTitle = t('shared.errorPage.title');
  const errorDescription = t('shared.errorPage.description');
  const supportEmail = t('shared.errorPage.email');

  return (
    <ErrorBoundary
      FallbackComponent={() => FallbackComponent({ description: errorDescription, supportEmail, title: errorTitle })}
      onError={myErrorHandler}
    >
      <ProjectContextProvider>
        <RequestContextProvider>
          <CompanyContextProvider>
            <StationContextProvider>
              <ThemeProvider>{children}</ThemeProvider>
            </StationContextProvider>
          </CompanyContextProvider>
        </RequestContextProvider>
      </ProjectContextProvider>
    </ErrorBoundary>
  );
};

'use client';

import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

import { useResolution } from '../../hooks';

interface Props {
  tabs: { label: string; content: React.ReactNode }[];
}

const TabPanel = ({ tabs }: Props) => {
  const [value, setValue] = useState(0);
  const { isTablet } = useResolution();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={({ customShadows }) => ({ borderRadius: 4, boxShadow: customShadows.main, my: 4, px: 2 })}>
      <Tabs value={value} onChange={handleChange} variant={isTablet ? 'fullWidth' : 'scrollable'} aria-label="tabs">
        {tabs.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </Tabs>

      <Box px={2} py={6}>
        {tabs[value].content}
      </Box>
    </Paper>
  );
};

export default TabPanel;

'use client';

import { styled, ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

interface Props extends ToggleButtonGroupProps {
  error: string | undefined;
  options: string[];
}

const StyledToggleGroup = styled(MuiToggleButtonGroup, {
  shouldForwardProp: (prop) => prop !== 'error',
})<Omit<Props, 'options'>>(({ error, theme: { palette } }) => ({
  '& button': {
    border: `1px solid ${error ? palette.error.main : palette.primary.main}`,
    color: `${error ? palette.error.main : palette.primary.main}`,
  },
}));

const ToggleButtonGroup = ({ options, ...rest }: Props) => (
  <StyledToggleGroup {...rest} color="primary">
    {options.map((option) => (
      <ToggleButton value={option} key={option}>
        {option}
      </ToggleButton>
    ))}
  </StyledToggleGroup>
);

export default ToggleButtonGroup;

import { Paper as MuiPaper, PaperProps } from '@mui/material';

type Props = PaperProps;

export const Paper = ({ children, sx, ...rest }: React.PropsWithChildren<Props>) => {
  return (
    <MuiPaper
      sx={{
        backgroundColor: 'common.white',
        borderRadius: 4,
        boxShadow: 3,
        mx: 'auto',
        p: { lg: '20px 30px', xs: '20px' },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiPaper>
  );
};

import { BottomNavigation as MuiBottomNavigation } from '@mui/material';
import { ReactNode } from 'react';

import BottomNavigationItem from './BottomNavigationItem';

type BottomNavigationProps = {
  children: ReactNode;
  currentPath: string;
};

const BottomNavigation = ({ children, currentPath: value }: BottomNavigationProps) => (
  <MuiBottomNavigation
    value={value}
    showLabels
    sx={({ customShadows, dimensions }) => ({
      bottom: 0,
      boxShadow: customShadows.bottomNavigation,
      display: { lg: 'none' },
      height: dimensions.bottomNavigationHeight,
      left: 0,
      p: 0,
      position: 'fixed',
      right: 0,
      zIndex: 'appBar',
    })}
  >
    {children}
  </MuiBottomNavigation>
);

BottomNavigation.Item = BottomNavigationItem;

export default BottomNavigation;

import { Link as MuiLink } from '@mui/material';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';

interface LinkProps extends MuiLinkProps {
  href: string;
  newTab?: boolean;
}

const Link = ({ href, newTab, ...rest }: LinkProps) => {
  const linkAttrs = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {};

  return <MuiLink href={href} {...linkAttrs} {...rest} />;
};

export default Link;

import { createContext, ReactNode, Reducer, useMemo, useReducer } from 'react';
import { countriesData } from 'ui';

import { DEFAULT_COUNTRY_BG } from '~/config/constants';

import { CHANGE_COMPANY_FORM_STATE } from './constants';

const defaultContext: CompanyContextType = {
  dispatch: () => null,

  state: {
    area: null,
    block: '',
    companyName: '',
    contactAddress: {
      area: null,
      municipality: null,
      region: null,
      block: '',
      settlement: null,
      street: '',
      streetNumber: '',
    },
    contactPerson: {
      email: '',
      firstName: '',
      lastName: '',
      phone: { country: DEFAULT_COUNTRY_BG, countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode, value: '' },
    },
    egn: '',
    eik: '',
    email: '',
    firstName: '',
    iban: '',
    isContactPersonSame: '',
    isLegalEntity: true,
    isMailingAddressSame: '',
    lastName: '',
    middleName: '',
    municipality: null,
    phone: { country: DEFAULT_COUNTRY_BG, countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode, value: '' },
    position: '',
    region: null,
    settlement: null,
    street: '',
    streetNumber: '',
  },
};

const stateReducer: Reducer<CompanyState, Action> = (prevState, action) => {
  switch (action.type) {
    case CHANGE_COMPANY_FORM_STATE:
      return {
        ...prevState,
        ...action.payload,
      };

    default:
      return prevState;
  }
};

export const CompanyContext = createContext<CompanyContextType>(defaultContext);

interface Props {
  children: ReactNode;
}

export const CompanyContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<Reducer<CompanyState, Action>>(stateReducer, defaultContext.state);

  const contextValue = useMemo(() => ({ dispatch, state }), [state, dispatch]);

  return <CompanyContext.Provider value={contextValue}>{children}</CompanyContext.Provider>;
};

'use client';

import { Stack } from '@mui/material';
import { Email, Phone } from 'ui/icons';

import { useResolution } from '../../hooks';
import CardListContainer from '../CardListContainer';
import { ListItem } from '../ListItem';
import PersonCard from '../PersonCard/PersonCard';

interface Props {
  list: { name: string; position: string; email: string; phone: string; id: string }[];
}

const PersonCardList = ({ list }: Props) => {
  const { isTablet } = useResolution();

  const cardsList = list.map(({ name, position, email, phone, id }) => (
    <PersonCard
      key={id}
      name={name}
      description={position}
      content={
        <Stack gap={isTablet ? 2 : 0}>
          <ListItem Icon={Phone} title={phone} size={isTablet ? 'lg' : 'sm'} />
          <ListItem Icon={Email} title={email} size={isTablet ? 'lg' : 'sm'} />
        </Stack>
      }
    />
  ));

  return <CardListContainer cardWidth="sm" elements={cardsList} />;
};

export default PersonCardList;

import { Divider as MuiDivider, DividerProps } from '@mui/material';

const Divider = ({ ...props }: DividerProps) => {
  const { sx } = props;
  return (
    <MuiDivider
      {...props}
      sx={[
        {
          bgcolor: 'primary.main',
          my: 4,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default Divider;

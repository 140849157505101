import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
  name: string;
  autocomplete?: string;
  uppercase?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  'data-testid'?: string;
  shrink?: boolean;
};

const TextField = ({ sx, ...rest }: TextFieldProps) => {
  return <MuiTextField variant="outlined" sx={sx} {...rest} />;
};

export default TextField;

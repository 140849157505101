import { List } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const SidebarMenu = ({ children }: Props) => <List>{children}</List>;

export default SidebarMenu;

import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = { component?: React.ElementType; href?: string; children: ReactNode };

const AppBarLogo = ({ children, ...rest }: Props) => (
  <Box
    sx={{
      display: 'flex',
      pl: { sm: 0, xs: 2 },
      visibility: { lg: 'hidden', xs: 'visible' },
    }}
    {...rest}
  >
    {children}
  </Box>
);

export default AppBarLogo;

import { SxProps, Theme } from '@mui/material';
import { FormHTMLAttributes } from 'react';

type Props = FormHTMLAttributes<HTMLFormElement> & {
  sx?: SxProps<Theme>;
};

const Form = ({ children, ...rest }: Props) => {
  return (
    <form {...rest} noValidate>
      {children}
    </form>
  );
};

export default Form;

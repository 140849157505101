'use client';

import { FormControl, InputLabel, MenuItem, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { useState } from 'react';

import SelectField from '../SelectField';

export type SortControlOption<T> = {
  key: string;
  label: string;
  order: T;
};

type Props<T> = {
  onChange: (option: SortControlOption<T>) => void;
  options: SortControlOption<T>[];
  label?: string;
  sx?: SxProps<Theme>;
};

export const SortControl = <T,>({ label, onChange, options, sx }: Props<T>) => {
  const [currentOption, setCurrentOption] = useState(options[0].label);

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setCurrentOption(event.target.value);

    const selectedOption = options.find((option) => option.label === event.target.value);

    if (selectedOption === undefined) {
      return;
    }

    onChange(selectedOption);
  };

  return (
    <FormControl sx={sx}>
      {label && <InputLabel id="sort-label">{label}</InputLabel>}

      <SelectField<string>
        labelId="sort-label"
        value={currentOption}
        label={label}
        onChange={handleSortChange}
        data-testid="sort-control"
        variant="outlined"
      >
        {options.map((option) => (
          <MenuItem key={option.label} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </SelectField>
    </FormControl>
  );
};

export const countriesData = {
  ad: {
    countryCode: '+376',
    countryName: 'Andorra',
  },
  al: {
    countryCode: '+355',
    countryName: 'Albania',
  },
  ar: {
    countryCode: '+374',
    countryName: 'Armenia',
  },
  at: {
    countryCode: '+43',
    countryName: 'Austria',
  },
  ba: {
    countryCode: '+387',
    countryName: 'Bosnia and Herzegovina',
  },
  be: {
    countryCode: '+32',
    countryName: 'Belgium',
  },
  bg: {
    countryCode: '+359',
    countryName: 'Bulgaria',
  },
  by: {
    countryCode: '+375',
    countryName: 'Belarus',
  },
  ch: {
    countryCode: '+41',
    countryName: 'Switzerland',
  },
  cy: {
    countryCode: '+357',
    countryName: 'Cyprus',
  },
  cz: {
    countryCode: '+420',
    countryName: 'Czech Republic',
  },
  de: {
    countryCode: '+49',
    countryName: 'Germany',
  },
  dk: {
    countryCode: '+45',
    countryName: 'Denmark',
  },
  ee: {
    countryCode: '+372',
    countryName: 'Estonia',
  },
  es: {
    countryCode: '+34',
    countryName: 'Spain',
  },
  fi: {
    countryCode: '+358',
    countryName: 'Finland',
  },
  fo: {
    countryCode: '+298',
    countryName: 'Faroe Islands',
  },
  fr: {
    countryCode: '+33',
    countryName: 'France',
  },
  gb: {
    countryCode: '+44',
    countryName: 'United Kingdom',
  },

  ge: {
    countryCode: '+995',
    countryName: 'Georgia',
  },
  gi: {
    countryCode: '+350',
    countryName: 'Gibraltar',
  },
  gr: {
    countryCode: '+30',
    countryName: 'Greece',
  },
  hr: {
    countryCode: '+385',
    countryName: 'Croatia',
  },
  hu: {
    countryCode: '+36',
    countryName: 'Hungary',
  },
  ie: {
    countryCode: '+353',
    countryName: 'Ireland',
  },
  is: {
    countryCode: '+354',
    countryName: 'Iceland',
  },
  it: {
    countryCode: '+39',
    countryName: 'Italy',
  },
  li: {
    countryCode: '+423',
    countryName: 'Liechtenstein',
  },
  lt: {
    countryCode: '+370',
    countryName: 'Lithuania',
  },
  lu: {
    countryCode: '+352',
    countryName: 'Luxembourg',
  },
  lv: {
    countryCode: '+371',
    countryName: 'Latvia',
  },
  mc: {
    countryCode: '+377',
    countryName: 'Monako',
  },
  md: {
    countryCode: '+373',
    countryName: 'Moldova',
  },
  me: {
    countryCode: '+382',
    countryName: 'Montenegro',
  },
  mk: {
    countryCode: '+389',
    countryName: 'North Macedonia',
  },
  mt: {
    countryCode: '+356',
    countryName: 'Malta',
  },
  nl: {
    countryCode: '+31',
    countryName: 'Netherlands',
  },
  no: {
    countryCode: '+47',
    countryName: 'Norway',
  },
  pl: {
    countryCode: '+48',
    countryName: 'Poland',
  },
  pt: {
    countryCode: '+351',
    countryName: 'Portugal',
  },
  ro: {
    countryCode: '+40',
    countryName: 'Romania',
  },
  rs: {
    countryCode: '+381',
    countryName: 'Serbia',
  },
  ru: {
    countryCode: '+7',
    countryName: 'Russia',
  },
  se: {
    countryCode: '+46',
    countryName: 'Sweden',
  },
  si: {
    countryCode: '+386',
    countryName: 'Slovenia',
  },
  sk: {
    countryCode: '+421',
    countryName: 'Slovakia',
  },
  sm: {
    countryCode: '+378',
    countryName: 'San Marino',
  },
  ua: {
    countryCode: '+380',
    countryName: 'Ukraine',
  },
  va: {
    countryCode: '+39',
    countryName: 'Vatican City State',
  },
  xk: {
    countryCode: '+381',
    countryName: 'Kosovo',
  },
};

export type CountryIsoCode = keyof typeof countriesData;
export const countriesIsoCodes = Object.keys(countriesData) as CountryIsoCode[];

export const isValidCountry = (value: string): value is CountryIsoCode => Object.keys(countriesData).includes(value);

import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import Card from '../Card/Card';
import { TypographyOverflow } from '../TypographyOverflow';

interface Props {
  customerName?: string;
  title: string;
  subtitle: string;
  details: ReactNode[];
  'data-testid'?: string;
  downloadIcon?: ReactNode;
}

export const ContractCard = ({ title, subtitle, customerName, details, downloadIcon, ...rest }: Props) => {
  return (
    <Card
      {...rest}
      headerActions={downloadIcon}
      title={
        <TypographyOverflow variant="h6" color="info.main" fontWeight="bold" lines={1} lineHeight={2}>
          {title}
        </TypographyOverflow>
      }
      subtitle={
        <Typography variant="body2" color="info.main">
          {subtitle}
        </Typography>
      }
      content={
        <Stack mt={2}>
          {details.map((detail, i) => (
            <Typography key={i} mb={1} variant="body2" color="textColor.light">
              {detail}
            </Typography>
          ))}
        </Stack>
      }
      cardGradient={customerName}
    />
  );
};

'use client';

import { useTranslation } from 'next-export-i18n';
import { useEffect, useState } from 'react';

import { Box } from './Box/Box';
import { Link } from './Link';
import { Logo } from './Logo';
import { Typography } from './Typography/Typography';

interface Props {
  to?: string | URL;
  waitSeconds?: number;
}

export const Redirect = ({ to = 'https://toki.bg', waitSeconds = 5 }: Props) => {
  const { t } = useTranslation();
  const [remainingSeconds, setRemainingSeconds] = useState(waitSeconds);
  const [url] = useState(new URL(to));

  const redirect = (address: URL) => {
    window.location.replace(address);
  };

  useEffect(() => {
    setTimeout(() => redirect(url), waitSeconds * 1000);
    setInterval(
      () => setRemainingSeconds((previousSeconds) => (previousSeconds > 0 ? previousSeconds - 1 : previousSeconds)),
      1000
    );
    // This should run only once at the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      width="100%"
      textAlign="center"
      zIndex={50}
      position="fixed"
      top={0}
      left={0}
      bgcolor="common.white"
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
        <Typography variant="subtitle1">
          {t('shared.redirect.redirectedTo')}{' '}
          <Box component="span" fontWeight="bold">
            {url.hostname}
          </Box>{' '}
          {t('shared.common.inAfter')} {remainingSeconds}{' '}
          {remainingSeconds !== 1 ? t('shared.common.seconds') : t('shared.common.second')}
        </Typography>
        <Logo />
        <Typography variant="subtitle1">
          {t('shared.redirect.noWait')} <Link href={`${to}`}>{t('shared.common.clickHere')}</Link>
        </Typography>
      </Box>
    </Box>
  );
};

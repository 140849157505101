const locations = ['shared', 'onboarding', 'switching'];

const i18n = {
  translations: locations.reduce(
    (acc, location) => {
      const en = require(`./translations/${location}/${location}.en.json`);
      const bg = require(`./translations/${location}/${location}.bg.json`);

      return {
        en: { ...acc.en, [location]: en },
        bg: { ...acc.bg, [location]: bg },
      };
    },
    { en: {}, bg: {} }
  ),
  defaultLang: 'bg',
};

module.exports = i18n;

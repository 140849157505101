import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { countriesData, CountryIsoCode } from '../config';
import { Flag } from './Flag';

type Props = {
  isoCode: CountryIsoCode;
};

export const FlagSelect = ({ isoCode }: Props) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="space-between" key={isoCode} data-testid="flag-select-test-phone">
      <Box display="flex" alignItems="center" gap={1}>
        <Box width={theme.spacing(4)}>
          <Flag isoCode={isoCode} />
        </Box>
        <Typography>{countriesData[isoCode].countryName}</Typography>
      </Box>
      <Typography>{countriesData[isoCode].countryCode}</Typography>
    </Box>
  );
};

'use client';

import { Box, Skeleton } from '@mui/material';

import { useResolution } from '../../hooks';
import { AppBar } from '../AppBar';
import { Logo } from '../Logo';
import { Sidebar } from '../Sidebar';

export const AppLoader = ({
  sidebarItemsCount = 3,
  contentItemsCount = 3,
}: {
  sidebarItemsCount?: number;
  contentItemsCount?: number;
}) => {
  const { isDesktop } = useResolution();

  const contentSkeletons = Array.from({ length: contentItemsCount });

  return (
    <Box
      display="flex"
      sx={(theme) => ({
        backgroundColor: theme.backgrounds.page,
        height: theme.dimensions.pageHeight,
      })}
    >
      {isDesktop && (
        <Sidebar>
          <Sidebar.Logo>
            <Logo />
          </Sidebar.Logo>
          <Sidebar.Menu>
            {Array.from({ length: sidebarItemsCount }).map((_, i) => (
              <Sidebar.Item key={i}>
                <Skeleton variant="text" />
              </Sidebar.Item>
            ))}
          </Sidebar.Menu>
        </Sidebar>
      )}

      <Box width="100%" height="100%" overflow="auto">
        <AppBar>
          <AppBar.Logo>
            <Logo />
          </AppBar.Logo>
          <AppBar.Actions>
            <Skeleton variant="circular" width={40} height={40} />
          </AppBar.Actions>
        </AppBar>
        <Box height="100%" px={{ lg: 6, xs: 2 }} pt={2.5} pb={{ lg: 5, xs: 20 }}>
          {contentSkeletons.map((_, i) => (
            <Box mb={2} key={i}>
              {contentSkeletons.map((__, j) => (
                <Skeleton key={`${i}-${j}`} />
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

'use client';

import { styled, TableCell as MuiTableCell, tableCellClasses, TableCellProps } from '@mui/material';

type Props = TableCellProps;

const StyledTableCell = styled(MuiTableCell)<Props>(({ color }) => ({
  alignItems: 'left',
  [`&.${tableCellClasses.head}`]: {
    color,
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

export const TableCell = ({ color, ...rest }: Props) => <StyledTableCell color={color} {...rest} />;

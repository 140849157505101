import { AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Toolbar } from '@mui/material';
import { ReactNode } from 'react';

import AppBarActions from './AppBarActions';
import AppBarLogo from './AppBarLogo';

interface AppBarProps extends MuiAppBarProps {
  children: ReactNode;
}

const AppBar = ({ children, ...rest }: AppBarProps) => {
  return (
    <MuiAppBar position="static" {...rest} elevation={0}>
      <Toolbar
        sx={(theme) => ({
          bgcolor: 'common.white',
          display: 'flex',
          height: theme.dimensions.appBarHeight,
          justifyContent: 'space-between',
        })}
      >
        {children}
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.Logo = AppBarLogo;
AppBar.Actions = AppBarActions;

export default AppBar;

'use client';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

const FormControlGroupLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiRadio-root': { color: theme.palette.secondary.main },
  '& .MuiTouchRipple-root': { color: theme.palette.secondary.main },
  '& .RadioButtonCheckedIcon': { color: theme.palette.secondary.main },
}));

export default FormControlGroupLabel;

import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import React, { ReactNode } from 'react';

export type CheckboxProps = MuiCheckboxProps & {
  label?: string | ReactNode;
  name: string;
  autocomplete?: string;
  'data-testid'?: string;
  disabled?: boolean;
  error?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { error, ...rest } = props;
  return <MuiCheckbox {...rest} color={error ? 'error' : 'primary'} inputRef={ref} />;
});

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

import { Color, colorsMap } from './colorsMap';

export type BadgeProps = {
  content: string;
  color: Color;
};

interface BadgeContentProps {
  content: string;
  color: Color;
  size: number;
}

const BadgeContent = ({ content, color, size }: BadgeContentProps): ReactElement => {
  return (
    <Box
      width={size}
      height={size}
      sx={{
        alignItems: 'center',
        backgroundColor: colorsMap[color],
        borderRadius: 2,
        color: 'common.white',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography fontWeight="bold" fontSize={size / 2}>
        {content}
      </Typography>
    </Box>
  );
};

export default BadgeContent;

import { SurveyScript } from 'survey';

import { Layout } from '~/components/Layout';

import { AppProvider } from '../src/styles/providers/app';

interface Props {
  Component: React.ElementType;
  pageProps: Record<string, unknown>;
}

const MyApp = ({ Component, pageProps }: Props) => {
  return (
    <AppProvider>
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <SurveyScript />
    </AppProvider>
  );
};

export default MyApp;

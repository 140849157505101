import { Box } from '@mui/system';
import { ReactNode } from 'react';

import { Container } from './Container';
import { Logo } from './Logo';

type Props = {
  children?: ReactNode;
};

export const Navbar = ({ children }: Props) => {
  return (
    <Box width="100%" boxShadow={3} mb={8}>
      <Container>
        <Box my={2}>
          <Logo />
        </Box>
        {children}
      </Container>
    </Box>
  );
};

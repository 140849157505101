import { Drawer } from '@mui/material';
import { ReactNode } from 'react';

import SidebarItem from './SidebarItem';
import SidebarLogo from './SidebarLogo';
import SidebarMenu from './SidebarMenu';

type SidebarProps = {
  children: ReactNode;
};

const Sidebar = ({ children }: SidebarProps) => (
  <Drawer
    sx={(theme) => ({
      '& .MuiDrawer-paper': {
        borderRight: 'none',
        boxShadow: theme.customShadows.sidebar,
        boxSizing: 'border-box',
        paddingX: '30px',
        width: theme.dimensions.sidebarWidth,
      },
      display: { lg: 'flex', xs: 'none' },
      width: theme.dimensions.sidebarWidth,
    })}
    variant="permanent"
    anchor="left"
  >
    {children}
  </Drawer>
);

Sidebar.Item = SidebarItem;
Sidebar.Logo = SidebarLogo;
Sidebar.Menu = SidebarMenu;

export default Sidebar;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidEnvironment = void 0;
var index_1 = require("../types/index");
var isValidEnvironment = function (environment) {
    if (!environment)
        return false;
    if (environment in index_1.VALID_ENVIRONMENTS) {
        return true;
    }
    return environment in index_1.VALID_ENVIRONMENTS;
};
exports.isValidEnvironment = isValidEnvironment;

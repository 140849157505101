export enum Actions {
  SWITCHING_INPUT_MAILING_DATA = 'SWITCHING_INPUT_MAILING_DATA',
  SWITCHING_INPUT_COMPANY_INFO = 'SWITCHING_INPUT_COMPANY_INFO',
  SWITCHING_INPUT_METERING_POINTS = 'SWITCHING_INPUT_METERING_POINTS',
  SWITCHING_DOWNLOAD_CONTRACT = 'SWITCHING_DOWNLOAD_CONTRACT',
  SWITCHING_COMPLETE_FORM = 'SWITCHING_COMPLETE_FORM',
  ONBOARDING_SELECT_PLAN_TYPE = 'ONBOARDING_SELECT_PLAN_TYPE',
  ONBOARDING_SELECT_CONSUMER_TYPE = 'ONBOARDING_SELECT_CONSUMER_TYPE',
  ONBOARDING_INPUT_COMPANY_INFO = 'ONBOARDING_INPUT_COMPANY_INFO',
  ONBOARDING_COMPLETE_FORM = 'ONBOARDING_COMPLETE_FORM',
  ONBOARDING_SELECT_PROJECT_TYPE = 'ONBOARDING_SELECT_PROJECT_TYPE',
  SHOW_NPS = 'SHOW_NPS',
  RESPONSE_NPS = 'RESPONSE_NPS',
  BLENDER_DOWNLOAD_INVOICE_PDF = 'BLENDER_DOWNLOAD_INVOICE_PDF',
  BLENDER_DOWNLOAD_HOURLY_REPORT = 'BLENDER_DOWNLOAD_HOURLY_REPORT',
}

'use client';

import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'next-export-i18n';
import { useState } from 'react';

import { Button } from './Button';

export const ErpMap = () => {
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  return (
    <>
      <Box
        component="span"
        onClick={handleOpen}
        onKeyDown={handleOpen}
        tabIndex={0}
        fontSize={theme.spacing(2)}
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        {t('shared.common.erpMap')}
      </Box>

      <Dialog onClose={handleClose} open={showModal} maxWidth="xl">
        <DialogContent>
          <img src="/images/map.svg" alt={t('shared.common.erpMap')} />
        </DialogContent>
        <DialogActions>
          <Button size="sm" onClick={handleClose}>
            {t('shared.common.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

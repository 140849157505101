"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMeteringPointWest = exports.isMeteringPoint = exports.isCompletedSwitchingRequest = exports.isPartiallyCompletedRequest = exports.isSwitchingRequest = exports.isOnboardingRequest = void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var isOnboardingRequest = function (request) {
    return request.type && request.type === 'onboarding';
};
exports.isOnboardingRequest = isOnboardingRequest;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var isSwitchingRequest = function (request) {
    return request.type && request.type === 'switching';
};
exports.isSwitchingRequest = isSwitchingRequest;
var isPartiallyCompletedRequest = function (request) {
    return Boolean(request.createdOn) &&
        Boolean(request.contact) && // TODO: check contact content
        Boolean(request.locations);
}; // TODO: check locations content
exports.isPartiallyCompletedRequest = isPartiallyCompletedRequest;
var isCompletedSwitchingRequest = function (request) {
    return (0, exports.isPartiallyCompletedRequest)(request) && Boolean(request.googleDriveFolderId);
};
exports.isCompletedSwitchingRequest = isCompletedSwitchingRequest;
var isMeteringPoint = function (data) {
    return Boolean(data.erp) && Boolean(data.meteringPoint);
};
exports.isMeteringPoint = isMeteringPoint;
var isMeteringPointWest = function (data) {
    if (!(0, exports.isMeteringPoint)(data)) {
        return false;
    }
    return data.erp === 'ЕРМ Запад';
};
exports.isMeteringPointWest = isMeteringPointWest;

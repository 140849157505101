export type Color = 'blue' | 'green' | 'purple' | 'purpleDark' | 'red' | 'teal' | 'white' | 'transparent';

export const colorsMap: { [K in Color]: string } = {
  blue: 'blue.main',
  green: 'primary.main',
  purple: 'purple.light',
  purpleDark: 'purple.dark',
  red: 'red.main',
  teal: 'teal.main',
  transparent: 'transparent',
  white: 'common.white',
};

import { createContext, ReactNode, Reducer, useMemo, useReducer } from 'react';
import { countriesData } from 'ui';

import { DEFAULT_COUNTRY_BG } from '~/config/constants';

import { CHANGE_REQUEST_FORM_STATE } from './constants';

const defaultContext: RequestContextType = {
  dispatch: () => null,

  state: {
    companyName: '',
    eik: '',
    email: '',
    energySource: '',
    erpRegion: [],
    exploitationDate: '',
    firstName: '',
    hasTBC: false,
    isAffiliated: '',
    lastName: '',
    middleName: '',
    phone: { country: DEFAULT_COUNTRY_BG, countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode, value: '' },
    power: 0,
  },
};

const stateReducer: Reducer<RequestFormState, Action> = (prevState: RequestFormState, action: Action) => {
  switch (action.type) {
    case CHANGE_REQUEST_FORM_STATE:
      return {
        ...prevState,
        ...action.payload,
      };

    default:
      return prevState;
  }
};

export const RequestContext = createContext<RequestContextType>(defaultContext);

interface Props {
  children: ReactNode;
}

export const RequestContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<Reducer<RequestFormState, Action>>(stateReducer, defaultContext.state);

  const contextValue = useMemo(() => ({ dispatch, state }), [state, dispatch]);

  return <RequestContext.Provider value={contextValue}>{children}</RequestContext.Provider>;
};

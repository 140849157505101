import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = { component?: React.ElementType; href?: string; children: ReactNode };

const SidebarLogo = ({ children, ...rest }: Props) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height={(theme) => theme.dimensions.appBarHeight}
    paddingY="28px"
    {...rest}
  >
    {children}
  </Box>
);

export default SidebarLogo;

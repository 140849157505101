import { createContext, ReactNode, Reducer, useMemo, useReducer } from 'react';

import { CHANGE_PLAN_TYPE, CHANGE_PRODUCER_TYPE, CHANGE_PROJECT_TYPE } from './constants';

const defaultContext: ProjectContextType = {
  dispatch: () => null,

  state: {
    plan_type: 'exchange',
    producer_type: 'Producer',
    project_type: undefined,
  },
};

const stateReducer: Reducer<ProjectState, Action> = (prevState, action) => {
  switch (action.type) {
    case CHANGE_PLAN_TYPE:
      return {
        ...prevState,
        plan_type: action.payload,
      };
    case CHANGE_PROJECT_TYPE:
      return {
        ...prevState,
        project_type: action.payload,
      };
    case CHANGE_PRODUCER_TYPE:
      return {
        ...prevState,
        producer_type: action.payload,
      };
    default:
      return prevState;
  }
};

export const ProjectContext = createContext<ProjectContextType>(defaultContext);

interface Props {
  children: ReactNode;
}

export const ProjectContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer<Reducer<ProjectState, Action>>(stateReducer, defaultContext.state);

  const contextValue = useMemo(() => ({ dispatch, state }), [state, dispatch]);

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};

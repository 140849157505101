'use client';

import { styled } from '@mui/system';

interface Props {
  source?: string;
}

const LogoImage = styled('img')(({ theme }) => ({
  display: 'inline-block',
  maxWidth: theme.spacing(20),
  width: '100%',
}));

export const Logo = ({ source = '/images/logo.svg' }: Props) => {
  return <LogoImage src={source} alt="Company Logo" />;
};
